<template>
  <div class="agreement-document">
    <div>
      <h1 class="title">{{ pkname }} - 协议文档</h1>
      <div>
        <h2 id="description">文档说明</h2>
        <p>
          本文件作为仅声明设备协议相关部分。
        </p>
        <p>生成时间：{{ createTime }}</p>
        <!-- <a href="" @click="print" class="doc-link pdf">下载为pdf</a> -->
        <h2 id="device-protocol">设备相关协议</h2>
        <p ng-if="vm.protocol.workModeType !== 'TRANSPARENT48' && vm.protocol.workModeType !== 'TRANSPARENT48_V2'">
          设备协议是指网关设备与云端之间通信的业务协议，包含具体的查询控制命令，
          控制命令下发的动作(action为<code class="code">cloudSend</code>)，
          设备上报的动作(action为<code class="code">devSend</code>)。
        </p>
        <div v-for="item in cmdlist" :key="item.cmd">
          <h3 :id="item.cmd">{{ item.cmd }}<span class="quote">{{ item.name }}</span></h3>
          <el-table :data="item.paramList" border style="width: 94%;" class="noprint">
            <el-table-column prop="name" label="参数名称" style="width: 20%">
            </el-table-column>
            <el-table-column prop="param" label="标识符" style="width: 20%">
            </el-table-column>
            <el-table-column prop="dataType" label="数据类型" style="width: 20%">
            </el-table-column>
            <el-table-column prop="frameType" label="传输类型" style="width: 20%">
            </el-table-column>
            <el-table-column prop="attribute" label="属性" style="width: 20%">
            </el-table-column>
          </el-table>
          <p>示例帧</p>
          <pre class="sample">{{ item.DemoJSON }}</pre>

        </div>
      </div>

      <ul class="nav-list page-nav">
        <li class="nav-item"><a @click="go('description')" class="doc-link">文档说明</a></li>
        <li class="nav-item">
          <a @click="go('device-protocol')" class="doc-link">设备相关协议</a>
          <ul class="nav-list">
            <li v-for="item in cmdlist" class="nav-item" :key="item.cmd">
              <el-tooltip class="item" effect="dark" :content="item.name" placement="left">
                <a @click="go(item.cmd)" class="doc-link">{{ item.cmd }}</a>
              </el-tooltip>
            </li>
          </ul>
        </li>
      </ul>
      <div></div>
      <!-- <div class="plus-button print-button">
        <el-tooltip placement="top" content="打印">
          <el-button type="primary" :icon="Printer" circle @click="print()" />
        </el-tooltip>
      </div> -->
    </div>
  </div>
</template>

<script setup>
// import { Printer } from '@element-plus/icons-vue';
import { ref } from 'vue';
import { useRoute } from "vue-router";
import api from '@/api/api'
const route = useRoute();
const pkname = ref(null);
const cmdlist = ref([]);
const createTime = ref(null);
const getInfo = () => {
  const { name, pk } = route.query;
  pkname.value = name;
  api.protocolModel(pk).then(res => {
    if (res.code === "0") {
      let cmdList = res.res.data.cmds
      const paramsData = res.res.data.params
      cmdList = cmdList.map(item => {
        return {
          ...item,
          paramList: [],
          DemoJSON: {},
        }
      })
      for (let k = 0; k < cmdList.length; k++) {
        if (cmdList[k].frameType === 'DEV_DOWN') {
          // 下发
          cmdList[k].DemoJSON = {
            action: 'cloudSend',
            msgId: '1',
            pk: pk,
            devId: 'devId',
            data: {
              cmd: cmdList[k].cmd,
              params: {}
            }
          }
        } else if (cmdList[k].frameType === 'DEV_UP') {
          // 上报
          cmdList[k].DemoJSON = {
            action: 'devSend',
            msgId: '1',
            pk: pk,
            devId: 'devId',
            data: {
              cmd: cmdList[k].cmd,
              params: {}
            }
          }
        }

        for (let i = 0; i < paramsData.length; i++) {
          if (cmdList[k].params.length !== 0) {
            for (let j = 0; j < cmdList[k].params.length; j++) {
              if (cmdList[k].params[j] === paramsData[i].param) {
                let keyName = paramsData[i].param
                let type = paramsData[i].checkType.type
                let arr = paramsData[i].checkType.values
                let attribute = ''
                if (type === 'NONE') {
                  attribute = '不校验'
                } else if (type === 'ENUM') {
                  for (let i = 0; i < arr.length; i++) {
                    let value = arr[i].value + ' [' + arr[i].desc + ']'
                    if (i < arr.length - 1) {
                      attribute = attribute + ' ' + value + '；' + '\n'
                    } else {
                      attribute = attribute + ' ' + value
                    }
                    cmdList[k].DemoJSON.data.params[keyName] = arr[0].value
                  }
                } else {
                  attribute = '[' + paramsData[i].checkType.min.value + ' , ' + paramsData[i].checkType.max.value + ']'
                  if (paramsData[i].dataType === 'INT' || paramsData[i].dataType === 'FLOAT') {
                    cmdList[k].DemoJSON.data.params[keyName] = 0
                  } else {
                    cmdList[k].DemoJSON.data.params[keyName] = ''
                  }
                }
                let frameType = paramsData[i].frameType
                if (frameType === 'DEV_UP_DOWN') {
                  frameType = '可上报可下发'
                } else if (frameType === 'DEV_DOWN') {
                  frameType = '只下发'
                } else if (frameType === 'DEV_UP') {
                  frameType = '只上报'
                }
                cmdList[k].paramList.push({
                  name: paramsData[i].name,
                  param: paramsData[i].param,
                  dataType: paramsData[i].dataType,
                  attribute: attribute,
                  frameType: frameType
                })
              }
            }
          }
        }
      }
      createTime.value = res.res.data.createdDate;
      cmdlist.value = cmdList;
    }
  })
}
getInfo();

// const print = () => {
//   window.print()
// }
const go = (label) => {
  document.getElementById(label).scrollIntoView()
}
</script>

<style lang="less" scoped>
.agreement-document {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.title {
  color: #212121;
  font-size: 36px;
  font-weight: 500;
  color: #337ab7;
  margin-top: 10px;
}

.nav-list {
  list-style-type: none;
  padding-left: 12px;
}

.doc-link {
  cursor: pointer
}

h2 {
  border-bottom: 1px solid #ebebeb;
  font: 300 24px/32px Roboto, sans-serif;
  letter-spacing: -.01em;
  margin: 40px 0 20px;
  padding-bottom: 3px;
  font-weight: bold;
}

.nav-item {
  list-style-type: none;
  padding: 0;
  margin: 8px 0;

}

h3 {
  font-weight: bold;
  padding: 10px 0;
}

a {
  color: #337ab7;
}

.page-nav {
  border-left: 4px solid #337ab7;
  position: fixed;
  right: 5%;
  top: 2%;
  max-height: calc(100% - 120px);
  overflow-x: hidden;
}

.code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

p {
  padding: 10px 0;
  color: #212121;
  font: 400 16px/24px Roboto, sans-serif;
}

.quote {
  font: 13px/20px Roboto, sans-serif;
  color: #757575;
  margin-left: 4px;
}

.el-table thead {
  color: #909399;
  font-weight: 500;
}

.el-table th {
  font-size: 0.6rem !important;
  font-weight: 500 !important;
  height: 2.5rem !important;
  padding-left: 1rem;
  color: #131528;
  background-color: #edf1f7;
}

.el-table td {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #0F1430 !important;
  height: 50px !important;
  padding: 0px 0px 0px 20px !important;
}

.sample {
  width: 92.3%;
  padding: 10px;
  margin: 0 0 10px;
  font-size: 13px;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.el-table .cell {
  white-space: pre-line;
}

.plus-button {
  z-index: 3;
  position: fixed;
  bottom: 50px;
  right: 50px;
  transform: scale(1.8);
}
</style>